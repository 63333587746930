// notificationConstants.js
'use strict';

export const notificationCategory = {
  ALL: 'all',
  // notification category
  SYSTEM: 'system',
  // journal category
  RECEIVER: 'receiver',
  SENDER: 'sender',
  PURCHASER: 'purchaser',
  AUTO_CHAT: 'auto_chat',
};

export const notificationReadType = {
  UNREAD: 0,
  READ: 1,
};

export const notificationList = {
  NOTIFICATION_CENTER: 'notification-center',
  AUTO_CHAT: 'auto-chat',
  READ: 'read',
};

export const notificationTag = {
  AUTO_CHAT: 'auto_chat',
};

export const NOTIFICATION_CENTER_PAGE_LIMIT = 100;
