// NotificationButton.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UniversalContainer from './UniversalContainer.jsx';

import { ButtonId } from '../resource/mixpanel.js';
import getNavigationIcon, {
  NavigationId,
} from '../resource/getNavigationIcon.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';

export class NotificationButton extends React.Component {
  nextTick = undefined;
  getUnreadCount = () => {
    const { notificationIds, notificationReadIds } = this.props;
    const unreadNotificationIds = notificationIds.filter(
      notificationId => !notificationReadIds.includes(notificationId)
    );
    return unreadNotificationIds.length;
  };
  fetchMeNotifications = () => {
    const { meId, isFirstPageFetched, fetchMeNotifications } = this.props;
    if (isFirstPageFetched || !meId) {
      return;
    }
    return fetchMeNotifications();
  };
  componentDidMount() {
    this.nextTick = setTimeout(() => {
      this.fetchMeNotifications();
    });
  }
  componentDidUpdate(prevProps) {
    const { meId } = this.props;
    if (meId !== prevProps.meId) {
      clearTimeout(this.nextTick);
      this.nextTick = setTimeout(() => {
        this.fetchMeNotifications();
      });
    }
  }
  componentWillUnmount() {
    this.nextTick && clearTimeout(this.nextTick);
  }
  render() {
    const { openNotificationCenterModal, isNotificationCenterOpened } =
      this.props;
    const unreadCount = this.getUnreadCount();
    return (
      <>
        <StyledNotificationButton
          data-element_id={ButtonId.Home.ButtonNotificationCenter}
          onClick={openNotificationCenterModal}
        >
          <NotificationIcon
            src={getNavigationIcon({
              navigationId: NavigationId.NOTIFICATION,
              hasUnread: unreadCount > 0,
              isMatch: isNotificationCenterOpened,
            })}
            alt="Notification"
          />
        </StyledNotificationButton>
        {/* If put `NotificationCenterPopupList` under `StyledNotificationButton`.
        Will also trigger `StyledNotificationButton` click event handler when user click popup notification that opened from notification center. */}
        <PopupListWrapper>
          <UniversalContainer filename="NotificationCenterPopupList" />
        </PopupListWrapper>
      </>
    );
  }
}

NotificationButton.propTypes = {
  meId: PropTypes.string,
  isFirstPageFetched: PropTypes.bool,
  notificationIds: PropTypes.array,
  notificationReadIds: PropTypes.array,
  openNotificationCenterModal: PropTypes.func,
  fetchMeNotifications: PropTypes.func,
  isNotificationCenterOpened: PropTypes.bool,
};

NotificationButton.defaultProps = {
  meId: null,
  isFirstPageFetched: false,
  notificationIds: [],
  notificationReadIds: [],
  openNotificationCenterModal: () => null,
  fetchMeNotifications: () => null,
  isNotificationCenterOpened: false,
};

const StyledNotificationButton = styled.button`
  ${ResetButtonStyle}
  display: grid;
  place-content: center;
  width: 28px;
  height: 28px;
`;

const NotificationIcon = styled.img`
  width: 28px;
  height: 28px;
`;

const PopupListWrapper = styled.div`
  > div {
    display: inline;
  }
`;

export default NotificationButton;
