// LoginSignUpButton.js
'use strict';
import { connect } from 'react-redux';
import login from '../action/login.js';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import LoginSignUpButton from '../component/LoginSignUpButton.jsx';

const mapDispatchToProps = dispatch => {
  return {
    login: ({ entry = 'login' }) => {
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: { selectPath: ['login'], data: { entry } },
      });
      return dispatch(login());
    },
  };
};
export default connect(null, mapDispatchToProps)(LoginSignUpButton);
