// getTranslationKey.js
'use strict';
const translationObject = {};

/**
 * Get translation key.
 * @param {string} {key} - translation key.
 * @param {boolean} {[shouldUpperCase = false]} - should upper case the key.
 * @return {string|undefined} Return formatted translation key.
 */
const getTranslationKey = ({ key, shouldUpperCase = false } = {}) => {
  if (!key) {
    return;
  }
  const objectKey = `${key}:${shouldUpperCase}`;
  let result = translationObject[objectKey];
  if (result) {
    return result;
  }
  const replacedString = key.replace(/[^a-z\d]/gi, '_');
  result = shouldUpperCase
    ? replacedString.toUpperCase()
    : replacedString.toLowerCase();
  translationObject[objectKey] = result;
  return result;
};

export default getTranslationKey;
