// DecryptionWrapper.jsx
import React, { useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LRUCache } from 'lru-cache';

import env from '../resource/env.js';

const objectUrlMap = new LRUCache({
  max: Number(env.DECRYPTED_RESOURCE_CACHE_SIZE || 256),
  dispose: value => URL.revokeObjectURL(value),
});
let splashObjectUrlCache; // no need to revoke this one

const DecryptionWrapper = ({
  isDecryptionRequired = false,
  children = null,
  isObjectUrlFetching = false,
  isObjectUrlFetched = false,
  isObjectUrlError = false,
  objectUrl: objectUrlProp = '',
  encryptedResourceUrl = '',
  isSplashObjectUrlFetching = false,
  isSplashObjectUrlFetched = false,
  splashObjectUrl: splashObjectUrlProp = '',
  encryptedSplashUrl = '',
  fetchDecryptedResource,
  removeDecryptedResourceData,
  clearNetworkingNodes,
  ...restProps
}) => {
  // (1)
  // Currently DecryptionWrapper only accept/process single objectUrl.
  // If there're multiple child components within it,
  // filter them to the one we really need.
  // (2)
  // For now the <picture> is the only chance we get multiple child components,
  // so we just discard all <source>s.
  const child = Array.isArray(children)
    ? children.find(c => {
        // case 1:
        // <picture>
        //   {sourceArray}
        //   ...
        // </picture>
        if (Array.isArray(c)) return null; // Drop it directly since there shouldn't be multiple <img>s.

        // case 2:
        // <picture>
        //   <source ...>
        //   <source ...>
        //   ...
        // </picture>
        return c.type !== 'source';
      })
    : children;
  const objectUrlCache = objectUrlMap.get(encryptedResourceUrl);
  const objectUrl = objectUrlCache || objectUrlProp;
  const splashObjectUrl = splashObjectUrlCache || splashObjectUrlProp;
  const fetchingTimerRef = useRef();
  const {
    decryptionType,
    source = {},
    trailerSource,
    splashVideo,
    shouldUseTrailer,
    shouldUseSplash,
  } = child.props || {}; // child.props might be empty for some components' test cases
  const shouldWaitResourceReady =
    decryptionType === 'video/mp4' ? shouldUseTrailer : true;
  const isValidEncryptedResourceUrl = useMemo(
    // Some resource urls are dynamic, they might be blob, local files, or some other domains.
    // For those cases, no need to decrypt them.
    () => encryptedResourceUrl.includes(env.PUBLIC_ENCRYPTED_URL_PREFIX),
    [encryptedResourceUrl]
  );

  if (splashObjectUrl) splashObjectUrlCache = splashObjectUrl;

  useEffect(() => {
    if (
      isDecryptionRequired &&
      shouldUseSplash &&
      encryptedSplashUrl &&
      !isSplashObjectUrlFetching &&
      !isSplashObjectUrlFetched &&
      !splashObjectUrl
    ) {
      fetchDecryptedResource({ encryptedResourceUrl: encryptedSplashUrl });
    }
  }, [
    isDecryptionRequired,
    shouldUseSplash,
    encryptedSplashUrl,
    isSplashObjectUrlFetched,
    isSplashObjectUrlFetching,
    splashObjectUrl,
    fetchDecryptedResource,
  ]);

  useEffect(() => {
    if (
      isDecryptionRequired &&
      shouldWaitResourceReady &&
      encryptedResourceUrl &&
      isValidEncryptedResourceUrl &&
      !objectUrl &&
      !isObjectUrlFetched &&
      !isObjectUrlFetching
    ) {
      clearTimeout(fetchingTimerRef.current);
      fetchingTimerRef.current = setTimeout(() => {
        fetchDecryptedResource({ encryptedResourceUrl });
      }, 0);
    }
  }, [
    encryptedResourceUrl,
    shouldWaitResourceReady,
    fetchDecryptedResource,
    isValidEncryptedResourceUrl,
    isDecryptionRequired,
    isObjectUrlFetching,
    isObjectUrlFetched,
    objectUrl,
  ]);

  // [Clean up part 1] clear redux
  useEffect(() => {
    return () => {
      if (isDecryptionRequired) {
        clearTimeout(fetchingTimerRef.current);

        // clear data in redux store to avoid data explosion. Take image for example:
        // 1. sd-preview to sd => clear sd-preview
        // 2. unmount => clear sd
        removeDecryptedResourceData({ encryptedResourceUrl });
        clearNetworkingNodes({ encryptedResourceUrl });
      }
    };
  }, [
    isDecryptionRequired,
    encryptedResourceUrl,
    clearNetworkingNodes,
    removeDecryptedResourceData,
  ]);

  // [Clean up part 2] set cache while `objectUrlProp` is not in use
  useEffect(() => {
    return () => {
      if (isDecryptionRequired && objectUrlProp) {
        // We push object url into LRU cache not only when 'unmount',
        // but also when target url changes, ex: 'null' => 'sd-preview' => 'null' => 'sd'
        if (objectUrlCache) {
          // Just in case. Shouldn't be here tho.
          URL.revokeObjectURL(objectUrlProp);
        } else {
          objectUrlMap.set(encryptedResourceUrl, objectUrlProp);
        }
      }
    };
    // We ONLY care about the `objectUrlProp` change (It's complementary for [Clean up part 1])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectUrlProp]);

  if (!isDecryptionRequired || !isValidEncryptedResourceUrl) {
    const childArray = React.Children.toArray(children);
    return childArray.map((child, index) =>
      React.cloneElement(child, {
        ...child.props,
        ...restProps, // there're might be `onLoad`, `onError` and `style` originated from StatefulImage
        key: index,
      })
    );
  }

  // This block should be placed prior to checking objectUrl.
  // Otherwise, it would return null without performing onError function.
  if (isObjectUrlError) {
    // Sample scenario: failed to decrypt (This makes StatefulImage able to show fallback image).

    // There're 2 cases:
    if (restProps?.onError) {
      // (case 1)
      // <StatefulImage>
      //   <LazyImage>
      //     <DecryptionWrapper>

      // In this case, DecryptionWrapper inherits onError() from StatefulImage via LazyImage,
      // that's why we can leverage it directly.
      restProps.onError();
      return null;
    } else if (decryptionType !== 'video/mp4') {
      // case 2:
      // <StatefulImage>
      //   <picture>
      //     <DecryptionWrapper>

      // It this case, we don't have onError() and DecryptionWrapper renders no <img> due to empty objectUrl,
      // so StatefulImage has no chance to trigger onError().
      // That's why we set a hidden image to trigger onError natively.
      return <img hidden src="" data-original_src={encryptedResourceUrl} />;
    }
    return null;
  }
  // objectUrl are required if: (1) it's an image (2) it's a video + it needs trailer
  if (shouldWaitResourceReady && !objectUrl) return null;
  // splashObjectUrl is required only if shouldUseSplash is true
  if (shouldUseSplash && !splashObjectUrl) return null;

  if (decryptionType === 'video/mp4') {
    // for NativeVideoPlayer
    if (trailerSource) {
      return React.cloneElement(child, {
        ...child.props,
        trailerSource: objectUrl,
      });
    }

    // for VideoPlayer, ShakaPlayer
    // 1. So far, VideoPlayer is for watch.swag.live, so it hasn't adopted this mechanism yet.
    // 2. Currently, only mp4 needs decryption (trailer & splash).
    // 3. Although manifest and the subsequent segments don't need to decrypt, they still have to use encrypted urls.
    let newSplashVideo;
    if (splashVideo) {
      newSplashVideo = Object.create(null);
      for (const key of Object.keys(splashVideo)) {
        newSplashVideo[key] = splashObjectUrl;
      }
    }

    let newSource = Object.create(null);
    for (const [key, value] of Object.entries(source)) {
      // replace all urls; not just trailers'
      newSource[key] =
        key === 'mp4'
          ? objectUrl
          : value?.replace(
              env.PUBLIC_URL_PREFIX,
              env.PUBLIC_ENCRYPTED_URL_PREFIX
            ) || '';
    }
    // [HEADS-UP]
    // 1.
    // DON'T alter props.source directly, update them in an immutable way.
    // Otherwise it causes the child to re-mount (potential video flicker).
    // 2.
    // We couldn't alter props.splashVideo directly either (even though it doesn't cause remount).
    // It may override the original splashVideo to empty because current video doesn't need it.
    // However, it makes the subsequent videos that genuinely need splash couldn't play.
    return React.cloneElement(child, {
      ...child.props,
      source: newSource,
      splashVideo: newSplashVideo,
    });
  }

  // for images
  return React.cloneElement(child, {
    ...child.props,
    ...restProps, // there might be `onLoad`, `onError` and `style` originated from StatefulImage
    'data-original_src': encryptedResourceUrl, // this is for debugging (src should be enough since we don't use srcSet for decryption)
    resourceUrl: undefined, // left public-encrypted.xxx (in `data-original_src`) instead of public.xxx url should be enough
    src: objectUrl,
    srcSet: undefined,
  });
};

DecryptionWrapper.propTypes = {
  isDecryptionRequired: PropTypes.bool,
  isSplashObjectUrlFetching: PropTypes.bool,
  isSplashObjectUrlFetched: PropTypes.bool,
  isObjectUrlFetching: PropTypes.bool,
  isObjectUrlFetched: PropTypes.bool,
  isObjectUrlError: PropTypes.bool,
  children: PropTypes.node,
  encryptedSplashUrl: PropTypes.string,
  encryptedResourceUrl: PropTypes.string,
  splashObjectUrl: PropTypes.string,
  objectUrl: PropTypes.string,
  fetchDecryptedResource: PropTypes.func.isRequired,
  removeDecryptedResourceData: PropTypes.func.isRequired,
  clearNetworkingNodes: PropTypes.func.isRequired,
};

export default DecryptionWrapper;
