// NotificationButton.js
import { connect } from 'react-redux';
import getNetworkingData from '../selector/getNetworkingData.js';
import getMeData from '../selector/getMeData.js';
import getListData from '../selector/getListData.js';
import getModalData from '../selector/getModalData.js';
import addModal from '../action/addModal.js';
import fetchMeNotifications from '../action/fetchMeNotifications.js';
import {
  notificationList,
  NOTIFICATION_CENTER_PAGE_LIMIT,
} from '../resource/notificationConstants.js';
import NotificationButton from '../component/NotificationButton.jsx';

const mapStateToProps = state => {
  const meId = getMeData(state, 'id');
  const notificationIds = getListData(
    state,
    ['notification', 'me', notificationList.NOTIFICATION_CENTER],
    'itemIds'
  );
  const notificationReadIds = getListData(
    state,
    ['notification', 'me', notificationList.READ],
    'itemIds'
  );
  return {
    meId,
    isFirstPageFetched: !!getNetworkingData(
      state,
      ['notification', 'me', notificationList.NOTIFICATION_CENTER, 1],
      'isFetched'
    ),
    notificationIds,
    notificationReadIds,
    isNotificationCenterOpened: ['display', 'opening'].includes(
      getModalData(state, 'NotificationCenterModal', 'status')
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMeNotifications: () =>
      dispatch(
        fetchMeNotifications({
          listType: notificationList.NOTIFICATION_CENTER,
          limit: NOTIFICATION_CENTER_PAGE_LIMIT,
        })
      ),
    openNotificationCenterModal: () =>
      dispatch(addModal({ id: 'NotificationCenterModal' })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationButton);
