// fetchDailyRewardStatus.js
'use strict';
import fetch from '../resource/customFetch.js';
import getMeData from '../selector/getMeData.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import {
  MERGE_ME_DATA,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Get the status of the current day and next day Daily Login status
 * @kind action
 * @return {Promise} Action promise.
 */
const fetchDailyRewardStatus = () => async (dispatch, getState) => {
  const token = getMeData(getState(), 'token');
  if (!token) return dispatch({ type: '' });

  const selectPath = ['me', 'dailyReward', 'status'];

  const fetchOptions = {
    method: 'GET',
    headers: {
      ...getHeaders(),
      Authorization: `Bearer ${token}`,
    },
  };

  const url = getResourceUrl({
    endpoint: '/aquisition/daily-login',
  });

  dispatch({
    type: SET_NETWORKING_FETCHING,
    payload: { selectPath },
  });

  try {
    const response = await fetch(url.href, fetchOptions);

    if (![200, 404].includes(response.status)) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: {
          selectPath,
          error: new Error('Not Expected Response Status'),
        },
      });
    }

    dispatch({
      type: MERGE_ME_DATA,
      payload: {
        dailyReward: {
          isCheckedIn: response.status === 200 ? true : false,
        },
      },
    });

    return dispatch({
      type: SET_NETWORKING_SUCCESS,
      payload: { selectPath },
    });
  } catch (error) {
    return dispatch({
      type: SET_NETWORKING_ERROR,
      payload: { selectPath, error },
    });
  }
};

export default fetchDailyRewardStatus;
