// leaderboardConstants.js
'use strict';

import { REGION_GLOBAL } from '../resource/regionConstants.js';

export const RANGE_24H = '24h';
export const RANGE_7D = '7d';
export const RANGE_30D = '30d';

export const RANGES = [RANGE_24H, RANGE_7D, RANGE_30D];

export const MAXIMUM_USER_CARD_AMOUNT_PER_ROW = 16;
export const USER_CARD_WIDTH = 104;
export const USER_CARD_HORIZONTAL_GAP = 10;
export const USER_CARDS_CONTAINER_HORIZONTAL_GAP = 10;

export const DEFAULT_REGION = REGION_GLOBAL;
