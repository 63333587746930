// BackpackButton.jsx
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonId } from '../resource/mixpanel.js';
import getNavigationIcon, {
  NavigationId,
} from '../resource/getNavigationIcon.js';

import { color } from '../style/variables.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';

const LOCAL_CACHE_SECONDS = 60; // TODO: remote config

export const BackpackButton = ({
  iconSize = 28,
  backpackFetchedTimestamp = 0,
  dailyRewardStatusFetchedTimestamp = 0,
  hasExpireSoonItem = false,
  shouldUseActiveIcon = false,
  isBackpackModalOpen = false,
  isFetchingBackpackItems = false,
  isFetchingBackpackDailyRewardStatus = false,
  canReceiveDailyReward = false,
  isAuthed = false,
  openBackpackModal = () => null,
  fetchBackpackItems = () => null,
  fetchDailyRewardStatus = () => null,
}) => {
  useEffect(() => {
    const shouldFetchNewData =
      Date.now() - backpackFetchedTimestamp >= LOCAL_CACHE_SECONDS * 1000;
    if (!isFetchingBackpackItems && shouldFetchNewData) {
      fetchBackpackItems();
    }
  }, [isFetchingBackpackItems, backpackFetchedTimestamp, fetchBackpackItems]);

  useEffect(() => {
    const shouldFetchNewData =
      Date.now() - dailyRewardStatusFetchedTimestamp >=
      LOCAL_CACHE_SECONDS * 1000;

    if (!isFetchingBackpackDailyRewardStatus && shouldFetchNewData) {
      fetchDailyRewardStatus();
    }
  }, [
    isAuthed,
    isFetchingBackpackDailyRewardStatus,
    dailyRewardStatusFetchedTimestamp,
    fetchDailyRewardStatus,
  ]);

  return (
    <StyledBackpackButton
      data-element_id={ButtonId.Backpack.ButtonBackpack}
      onClick={openBackpackModal}
    >
      {(hasExpireSoonItem || canReceiveDailyReward) && (
        <RedNotificationCircle isDefaultIconSize={iconSize === 28} />
      )}
      <img
        src={getNavigationIcon({
          navigationId: NavigationId.BACKPACK,
          isMatch: shouldUseActiveIcon && isBackpackModalOpen,
        })}
        alt="Backpack"
        width={iconSize}
        height={iconSize}
      />
    </StyledBackpackButton>
  );
};

BackpackButton.propTypes = {
  iconSize: PropTypes.number,
  backpackFetchedTimestamp: PropTypes.number,
  dailyRewardStatusFetchedTimestamp: PropTypes.number,
  hasExpireSoonItem: PropTypes.bool,
  shouldUseActiveIcon: PropTypes.bool,
  isBackpackModalOpen: PropTypes.bool,
  isFetchingBackpackItems: PropTypes.bool,
  isFetchingBackpackDailyRewardStatus: PropTypes.bool,
  canReceiveDailyReward: PropTypes.bool,
  isAuthed: PropTypes.bool,
  openBackpackModal: PropTypes.func,
  fetchBackpackItems: PropTypes.func,
  fetchDailyRewardStatus: PropTypes.func,
};

const StyledBackpackButton = styled.button`
  ${ResetButtonStyle}
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    object-fit: contain;
  }
`;

const RedNotificationCircle = styled.div`
  width: ${({ isDefaultIconSize }) => `${isDefaultIconSize ? 6 : 10}px`};
  height: ${({ isDefaultIconSize }) => `${isDefaultIconSize ? 6 : 10}px`};
  position: absolute;
  top: ${({ isDefaultIconSize }) => `${isDefaultIconSize ? 6 : 2}px`};
  right: ${({ isDefaultIconSize }) => `${isDefaultIconSize ? 6 : 4}px`};
  background-color: ${color.red};
  border-radius: 50%;
`;

export default BackpackButton;
