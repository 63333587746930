// toggleModal.js
'use strict';
import { MERGE_OPERATION_DATA, REMOVE_OPERATION_DATA } from '../ActionTypes.js';

import addModal from '../action/addModal.js';
import removeModals from '../action/removeModals.js';

/**
 * Toggle modal
 * @kind action
 * @param {string} {modalId} - modal id.
 * @param {object} {payload} - payload.
 * @param {boolean} {isOpen} - is open modal.
 * @param {object} {modalOption} - option for action/addModals.js and action/removeModals.js.
 * @return {Promise} Action promise.
 */
const toggleModal =
  ({ modalId, payload, isOpen, modalOption = {} } = {}) =>
  async dispatch => {
    if (!modalId) {
      return dispatch({ type: '' });
    }
    if (isOpen) {
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['modal', modalId],
          data: payload,
        },
      });
      return dispatch(
        addModal({
          id: modalId,
          transitionStatus: 'opening',
          ...modalOption,
        })
      );
    }
    await dispatch(
      removeModals({
        ids: [modalId],
        transitionStatus: 'closing',
        ...modalOption,
      })
    );
    return dispatch({
      type: REMOVE_OPERATION_DATA,
      payload: {
        selectPath: ['modal', modalId],
      },
    });
  };

export default toggleModal;
