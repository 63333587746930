// fetchMeNotifications.js
'use strict';
import { throttle } from 'lodash';
import parseLinkHeader from 'parse-link-header';
import fetch from '../resource/customFetch.js';
import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';
import getTimestampOffset, {
  Accuracy,
} from '../selector/getTimestampOffset.js';
import getConfigurations from '../selector/getConfigurations.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import objectifyArrayById from '../resource/objectifyArrayById.js';
import getCurrentUnixTimestamp from '../resource/getCurrentUnixTimestamp.js';
import {
  notificationList,
  notificationCategory,
} from '../resource/notificationConstants.js';
import { decodeUnicode } from '../resource/base64Utils.js';
import getIsAutoChatNotification from '../resource/getIsAutoChatNotification.js';

import {
  ADD_MESSAGES,
  ADD_NOTIFICATIONS,
  SET_LIST_ITEMS,
  ADD_LIST_ITEMS,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
  SET_OPERATION_DATA,
} from '../ActionTypes.js';

const API_THROTTLE_SECONDS = 2; // TODO: remote config
const throttleFunctionMap = {};

/**
 * Fetch me notifications
 * @kind action
 * @param {string} {listType} - the type of notification list.
 * @param {string} {[page = 1]} - the page number.
 * @param {string} {[limit = 10]} - page size.
 * @return {Promise} Action promise.
 */
export const fetchMeNotifications =
  (payload = {}) =>
  async dispatch => {
    const { listType } = payload;
    if (!throttleFunctionMap[listType]) {
      throttleFunctionMap[listType] = throttle(
        payload => dispatch(fetchMeNotificationsCore(payload)),
        API_THROTTLE_SECONDS * 1000
      );
    }
    return throttleFunctionMap[listType](payload);
  };

export const fetchMeNotificationsCore =
  ({ listType, limit = 20, page = 1 }) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');
    if (!token) return dispatch({ type: '' });

    const networkingSelectPath = ['notification', 'me', listType, page];
    const unixTimestamp = getOperationData(
      getState(),
      ['notifications'],
      'timestamp'
    );
    const timestampOffsetSeconds = getTimestampOffset(
      getState(),
      Accuracy.SECOND
    );
    const timestamp =
      unixTimestamp ||
      getCurrentUnixTimestamp({
        offsetSeconds: timestampOffsetSeconds,
      });

    if (!unixTimestamp) {
      dispatch({
        type: SET_OPERATION_DATA,
        payload: {
          selectPath: ['notifications', 'timestamp'],
          data: timestamp,
        },
      });
    }

    const fetchOptions = {
      method: 'GET',
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${token}`,
      },
    };

    const url = getResourceUrl({ endpoint: '/me/notification' });
    url.searchParams.set('limit', limit);
    url.searchParams.set('page', page);
    url.searchParams.set('_', timestamp);
    if (notificationList.NOTIFICATION_CENTER === listType) {
      url.searchParams.append('category', notificationCategory.SYSTEM);
    } else if (notificationList.AUTO_CHAT === listType) {
      const configurations = getConfigurations(getState()) || [];
      configurations.forEach(config => {
        url.searchParams.append('channel', config);
      });
    }

    dispatch({
      type: SET_NETWORKING_FETCHING,
      payload: { selectPath: networkingSelectPath },
    });
    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      const totalCount =
        parseInt(response.headers.get('x-total-count'), 10) || null;
      const links = parseLinkHeader(response.headers.get('Link'));
      const nextPage = links && links.next && parseInt(links.next.page, 10);
      const lastPage = links && links.last && parseInt(links.last.page, 10);

      const payload = await response.json();

      const notifications =
        notificationList.AUTO_CHAT === listType
          ? payload.filter(notification => {
              const { tag, link } = notification.data || {};
              return getIsAutoChatNotification({ tag, link });
            })
          : payload;
      const objectifyNotification = objectifyArrayById({
        array: notifications,
      });
      const notificationIds = Object.keys(objectifyNotification);

      const tasks = [];

      if (notificationList.AUTO_CHAT === listType) {
        const messages = notifications
          .map(notification => {
            const url = new URL(notification.data.link, 'http://localhost');
            const campaign = url.searchParams.get('campaign');
            if (campaign) {
              const decoded = JSON.parse(decodeUnicode({ str: campaign }));
              return {
                ...decoded,
                senderId: decoded?.sender,
                postedAtUnix: decoded?.postedAt,
              };
            }
            return;
          })
          .filter(message => message != null);
        const objectifyMessage = objectifyArrayById({
          array: messages,
        });

        tasks.push(
          dispatch({
            type: ADD_MESSAGES,
            payload: {
              messages: objectifyMessage,
            },
          })
        );
      }

      tasks.push(
        dispatch({
          type: ADD_NOTIFICATIONS,
          payload: { notifications: objectifyNotification },
        })
      );
      tasks.push(
        dispatch({
          type: page === 1 ? SET_LIST_ITEMS : ADD_LIST_ITEMS,
          payload: {
            selectPath: ['notification', 'me', listType],
            itemIds: notificationIds,
            totalCount,
            nextPage,
            lastPage,
          },
        })
      );

      await Promise.all(tasks);

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath: networkingSelectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath: networkingSelectPath, error },
      });
    }
  };

export default fetchMeNotifications;
