// UserLevelBadge.js
'use strict';
import { connect } from 'react-redux';

import { push } from '../action/navigationAction.js';
import getMeData from '../selector/getMeData.js';
import getUserData from '../selector/getUserData.js';
import UserLevelBadge from '../component/UserLevelBadge.jsx';
import getCurrentUserLevel from '../resource/getCurrentUserLevel.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { PUBLIC_BADGE_URL_PATH } from '../RemoteConfigKeys.js';
import getCurrentUnixTimestamp from '../resource/getCurrentUnixTimestamp.js';
import getTimestampOffset, {
  Accuracy,
} from '../selector/getTimestampOffset.js';

const mapStateToProps = (state, { level: staticLevel, userId }) => {
  let level = undefined;
  if (typeof staticLevel === 'number' && staticLevel >= 0) {
    level = staticLevel;
  } else if (userId) {
    const meId = getMeData(state, 'id');
    const levelData =
      userId === meId
        ? getMeData(state, 'level')
        : getUserData(state, userId, 'level');
    const timestampOffsetSeconds = getTimestampOffset(state, Accuracy.SECOND);
    const now = getCurrentUnixTimestamp({
      offsetSeconds: timestampOffsetSeconds,
    });
    level = getCurrentUserLevel({
      levelData,
      unixTimestamp: now,
    });
  }

  return {
    level,
    publicBadgeUrlPath: getRemoteConfigData(state, PUBLIC_BADGE_URL_PATH),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    directToInfomation: () => dispatch(push('/settings/vvip')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLevelBadge);
