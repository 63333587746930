// getAssetData.js
'use strict';
import { Collection } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

const defaultSelectPath = [];
const defaultDataKey = '';

/**
 * Select asset data
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} selectPath - select path.
 * @param {string} dataKey - data key.
 * @return {any} The selected asset data.
 */
const getAssetData = createCachedSelector(
  (state, selectPath = defaultSelectPath) =>
    state.getIn(['assets', ...selectPath]),
  (state, selectPath, dataKey) => dataKey,
  (asset, dataKey = defaultDataKey) => {
    let result = asset;
    if (dataKey) {
      result = asset?.get(dataKey);
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)(
  (state, selectPath = defaultSelectPath, dataKey = defaultDataKey) =>
    `${selectPath.join(':')}:${dataKey}`
);

export default getAssetData;
