// base64Utils.js
// Ref: https://stackoverflow.com/a/35450003
'use strict';
import base64url from 'base64-url';

export const decodeUnicode = ({ str }) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

export const encodeUnicode = ({ str, encoding = 'base64' }) => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  const base64 = btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode('0x' + p1);
    })
  );

  if ('base64' === encoding) {
    return base64;
  } else if ('base64url' === encoding) {
    return base64url.escape(base64);
  } else {
    throw new Error('unknown encoding');
  }
};
