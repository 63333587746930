// getMessageDetailLayout.js
'use strict';
import { SHORT_VIDEO_DURATION_SECONDS } from '../RemoteConfigKeys.js';

import getMessageData from '../selector/getMessageData.js';
import getAssetData from '../selector/getAssetData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import createCachedSelector from '../resource/createCachedSelector.js';
import { MediaAssetFormat } from '../resource/getMediaAsset.js';

/**
 * Select message detail type by message id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} messageId - message id.
 * @return {string} Return message detail type.
 */
const getMessageDetailLayout = createCachedSelector(
  (state, messageId) => messageId,
  state => getRemoteConfigData(state, SHORT_VIDEO_DURATION_SECONDS),
  (state, messageId) => {
    const assetIds = getMessageData(state, messageId, 'assetIds') || [];
    const firstAssetId = assetIds[0];
    return getAssetData(state, [firstAssetId], 'contentType');
  },
  (state, messageId) => {
    const assetIds = getMessageData(state, messageId, 'assetIds') || [];
    const firstAssetId = assetIds[0];
    return getAssetData(state, [firstAssetId], 'duration');
  },
  (state, messageId) => {
    const assetIds = getMessageData(state, messageId, 'assetIds') || [];
    const firstAssetId = assetIds[0];
    return getNetworkingData(
      state,
      ['fetch', 'manifest', messageId, firstAssetId, MediaAssetFormat.MPD],
      'isFetched'
    );
  },
  (
    messageId,
    shortVideoDuration,
    firstAssetContentType = '',
    firstAssetDuration,
    isFirstAssetManifestFetched
  ) => {
    const isVideo = firstAssetContentType.startsWith('video/');
    if (!isFirstAssetManifestFetched && !firstAssetDuration && isVideo) {
      return 'loading';
    }
    if (isVideo) {
      // TODO: refactor this to re-use logic
      if (shortVideoDuration && firstAssetDuration < shortVideoDuration) {
        return 'story';
      } else {
        return 'flix';
      }
    }
    return messageId ? 'post' : '';
  }
)((state, messageId) => `${messageId}`);

export default getMessageDetailLayout;
