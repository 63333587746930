// responseDecryptionUtil.js
'use strict';

import env from '../resource/env.js';

/**
 * Get decrypted data from given encrypted HTTP response.
 *
 * @param {HTTPResponse} {response} - The encrypted source.
 * @returns {Promise} decrypted binary data and content type.
 */
const responseDecryptionUtil = async ({ response }) => {
  const publicUrlEncryptKeys =
    (env.PUBLIC_URL_ENCRYPT_KEYS || '').split(' ').filter(Boolean) || [];
  const publicUrlEncryptKeyMap = publicUrlEncryptKeys.reduce((keyMap, key) => {
    const [keyId, keyValue] = key.split(':');
    keyMap.set(keyId, keyValue);
    return keyMap;
  }, new Map());
  const encryptedData = await response.arrayBuffer();
  const initializationVector = response.headers.get('x-encrypted-iv');
  const encryptionAlgorithm = response.headers.get('x-encrypted-algo');
  const encryptionKeyId = response.headers.get('x-encrypted-key-id');
  const contentType = response.headers.get('x-content-type');
  const subtleCrypto = window.crypto.subtle || window.crypto.webkitSubtle;
  const cryptoKey = await subtleCrypto.importKey(
    'raw',
    // Backend creates a Buffer with Buffer.from(), which is a subclass of JavaScript's Uint8Array class (https://nodejs.org/api/buffer.html)
    Uint8Array.from(
      atob(publicUrlEncryptKeyMap.get(encryptionKeyId)),
      // atob() returns an binary string, and then we use charCodeAt() to transform it to an array of bytes.
      c => c.charCodeAt(0)
    ),
    encryptionAlgorithm,
    false,
    ['decrypt']
  );
  const buffer = await subtleCrypto.decrypt(
    {
      name: encryptionAlgorithm,
      iv: Uint8Array.from(atob(initializationVector), c => c.charCodeAt(0)),
    },
    cryptoKey,
    encryptedData
  );
  return { buffer, contentType };
};

export default responseDecryptionUtil;
