// Tooltip.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class Tooltip extends React.PureComponent {
  state = {
    isActive: false,
  };
  nextTick = null;

  closeHint = () => {
    this.setState({ isActive: false });

    document.removeEventListener('click', this.closeHint);
  };

  openHint = () => {
    const { isActive } = this.state;

    if (isActive) {
      return;
    }

    this.setState({
      isActive: true,
    });
  };

  handleClick = () => {
    const { isActive } = this.state;

    if (isActive) {
      this.closeHint();
    } else {
      this.openHint();

      // Fix Tooltip been closed immediately
      this.nextTick = setTimeout(() => {
        document.addEventListener('click', this.closeHint);
      });
    }
  };

  renderHint() {
    const { position, renderHint, style } = this.props;
    const { isActive } = this.state;
    return (
      <Hint
        isActive={isActive}
        position={position}
        onClick={event => {
          event.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();
        }}
        style={style.hint}
      >
        {renderHint()}
      </Hint>
    );
  }

  componentWillUnmount() {
    clearTimeout(this.nextTick);
  }

  render() {
    const { children, trigger, disabled, style } = this.props;
    return (
      <StyledTooltip
        onClick={trigger === 'click' && !disabled ? this.handleClick : null}
        onMouseEnter={trigger === 'hover' && !disabled ? this.openHint : null}
        onMouseLeave={trigger === 'hover' && !disabled ? this.closeHint : null}
        style={style.wrapper}
      >
        {children}
        {this.renderHint()}
      </StyledTooltip>
    );
  }
}

Tooltip.propTypes = {
  position: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'bottom-center',
    'left',
  ]),
  trigger: PropTypes.oneOf(['click', 'hover']),
  children: PropTypes.node,
  renderHint: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

Tooltip.defaultProps = {
  position: 'left',
  trigger: 'click',
  renderHint: () => null,
  disabled: false,
  style: {},
};

const StyledTooltip = styled.div`
  position: relative;
`;

const transformPosition = ({ position }) => {
  switch (position) {
    case 'top':
      return 'bottom: 100%';
    case 'right':
      return `
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
      `;
    case 'bottom':
      return 'top: 100%';
    case 'bottom-center':
      return `
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      `;
    case 'left':
    default:
      return `
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
      `;
  }
};

const showHint = ({ isActive }) =>
  isActive
    ? `
  opacity: 1;
  visibility: visible;
`
    : `
  opacity: 0;
  visibility: hidden;
`;

const Hint = styled.div`
  position: absolute;
  transition: opacity 0.3s;

  ${transformPosition};
  ${showHint};
`;

export default Tooltip;
