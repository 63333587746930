// getUserBadges.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getMeData from '../selector/getMeData.js';
import getUserData from '../selector/getUserData.js';

import i18n from '../resource/i18n.js';
import getLeaderboardBadgeData from '../resource/getLeaderboardBadgeData.js';
import getTranslationKey from '../resource/getTranslationKey.js';

export const COUNTRY_BADGE_PREFIX = 'country:';
export const USER_LEVEL_BADGE_PREFIX = 'user_level_';
export const CAMPAIGN_BADGE_PREFIX = 'campaign_';

const defaultArray = [];

/**
 * detect badge is campaign badge or not.
 * @param {string} {badge} - badge.
 * @return {boolean} return `true` when badge is campaign badge.
 */
export const isCampaignBadge = ({ badge }) =>
  badge.startsWith(CAMPAIGN_BADGE_PREFIX);

/**
 * detect badge is user level badge or not.
 * @param {string} {badge} - badge.
 * @return {boolean} return `true` when badge is user level badge.
 */
export const isUserLevelBadge = ({ badge }) =>
  badge.startsWith(USER_LEVEL_BADGE_PREFIX);

/**
 * detect badge is language badge or not.
 * @param {object} payload - payload.
 * @param {string} payload.badge - badge.
 * @return {boolean} return `true` when badge is language badge.
 */
export const isLanguageBadge = ({ badge }) => badge.startsWith('lang:');

/**
 * detect badge is swagger badge or not.
 * @param {string} {badge} - badge.
 * @return {boolean} return `true` when badge is swagger badge.
 */
export const isSwaggerBadge = ({ badge }) => /^swagger$/.test(badge);

/**
 * detect badge is location badge or not.
 * @param {object} payload - payload.
 * @param {string} payload.badge - badge.
 * @return {boolean} return `true` when badge is language badge.
 */
export const isLocationBadge = ({ badge }) => badge.startsWith('location:');

/**
 * detect badge is country badge or not.
 * @param {object} payload - payload.
 * @param {string} payload.badge - badge.
 * @return {boolean} return `true` when badge is country badge.
 */
export const isCountryBadge = ({ badge }) =>
  badge.startsWith(COUNTRY_BADGE_PREFIX);

/**
 * detect badge has translation or not.
 * @param {object} payload - payload.
 * @param {string} payload.badge - badge.
 * @return {boolean} return `true` when badge has translation.
 */
export const hasTranslation = ({ badge }) => {
  let i18nKey = badge;

  const { isLeaderboardBadge, region } = getLeaderboardBadgeData({
    badgeName: badge,
  });

  if (isLeaderboardBadge) {
    i18nKey = `leaderboard_${region}`;
  }

  return (
    i18n.exists(i18nKey) ||
    i18n.exists(getTranslationKey({ key: `badge_${badge}` }))
  );
};

/**
 * Select user badges by user id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} userId - user id.
 * @param {String} type - the type string. ex: [null, 'langs', 'locations']
 * @return {Array} The selected user badges.
 */
const getUserBadges = createCachedSelector(
  (state, userId) => getBadges(state, userId),
  (state, userId, type) => type,
  (badges = defaultArray, type) => {
    if ('hasSwaggerBadge' === type) {
      return badges.includes('swagger');
    }
    if ('minimumBadges' === type) {
      let levelBadge;
      let leaderboardBadge;
      let leaderboardBadgePlace;
      const restBadges = [];
      badges.reverse().forEach(badge => {
        if (isUserLevelBadge({ badge })) {
          levelBadge = badge;
          return;
        }
        const { isLeaderboardBadge, place } = getLeaderboardBadgeData({
          badgeName: badge,
        });
        if (isLeaderboardBadge) {
          if (!leaderboardBadge || leaderboardBadgePlace > place) {
            leaderboardBadge = badge;
            leaderboardBadgePlace = place;
          }
          return;
        }
        if (
          isLocationBadge({ badge }) ||
          isCountryBadge({ badge }) ||
          isLanguageBadge({ badge }) ||
          !hasTranslation({ badge })
        ) {
          return;
        }
        if (restBadges.length < 2) {
          restBadges.unshift(badge);
          return;
        }
      });
      return [levelBadge, leaderboardBadge, ...restBadges].filter(
        badge => badge
      );
    }
    const result = [
      ...new Set(
        badges.filter(badge => {
          if (type === 'locations') {
            return isLocationBadge({ badge });
          }
          if (type === 'countries') {
            return isCountryBadge({ badge });
          }
          if (type === 'langs') {
            return isLanguageBadge({ badge });
          }
          if (
            !isCountryBadge({ badge }) &&
            !isLanguageBadge({ badge }) &&
            hasTranslation({ badge }) &&
            !isSwaggerBadge({ badge })
          ) {
            return true;
          }
          return false;
        })
      ),
    ];
    return result;
  }
)((state, userId, type) => `${userId}:${type}`);

export default getUserBadges;

/**
 * Select badges
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} userId - user id.
 * @return {Array} The selected user badges.
 */
const getBadges = createCachedSelector(
  (state, userId) => userId,
  (state, userId) => getUserData(state, userId, 'badges'),
  state => getMeData(state, 'id'),
  state => getMeData(state, 'badges'),
  (userId, userBadges = defaultArray, meId, meBadges = defaultArray) => {
    if (userId === meId) {
      return meBadges;
    }
    return userBadges;
  }
)((state, userId) => `${userId}`);
