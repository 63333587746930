// getIsPermissionMatched.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getMeData from '../selector/getMeData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

const defaultArray = [];

/*
 * Get is permission matched by remote config key
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} remoteConfigKey - remote config key.
 * @param {array} extraScopes - extra scope candidate to be matched
 * @return {boolean|undefined} Return true if token scopes include required scope.
 */
export const getIsPermissionMatched = createCachedSelector(
  state => getMeData(state, 'tokenScopes'),
  (state, remoteConfigKey) => getRemoteConfigData(state, remoteConfigKey),
  (state, remoteConfigKey, extraScopes) => extraScopes,
  (
    tokenScopes = defaultArray,
    scopes = defaultArray,
    extraScopes = defaultArray
  ) => {
    if ((!scopes.length && !extraScopes.length) || !tokenScopes.length) {
      return;
    }
    return [...new Set([...scopes, ...extraScopes])].some(scope =>
      tokenScopes.some(
        tokenScope => scope.toLowerCase() === tokenScope.toLowerCase()
      )
    );
  }
)(
  (state, remoteConfigKey, extraScopes = defaultArray) =>
    `${remoteConfigKey}:${extraScopes.join(',')}`
);

export default getIsPermissionMatched;
