// getShareLink.js
'use strict';

/**
 * Get share link
 * @param {object} {query} - should append android apn amv query
 * @param {string} {pathname} - overwrite current pathname
 * @return {string} share link winth query
 */
const getShareLink = ({ query = {}, pathname } = {}) => {
  const isServer = typeof window === 'undefined';
  if (isServer) return null;

  const url = pathname
    ? new URL(pathname, window.location.origin)
    : new URL(window.location?.href);

  Object.keys(query).map(key => {
    url.searchParams.set(key, query[key]);
  });

  return url.href;
};

export default getShareLink;
