// fetchBackpackItems.js
import parseLinkHeader from 'parse-link-header';
import { differenceInSeconds } from 'date-fns';
import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
  ADD_BACKPACK_ITEMS,
  SET_LIST_ITEMS,
  ADD_LIST_ITEMS,
  MERGE_OPERATION_DATA,
} from '../ActionTypes.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import handleFetchError from '../resource/handleFetchError.js';
import objectifyArrayById from '../resource/objectifyArrayById.js';
import getMeData from '../selector/getMeData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { BACKPACK_EXPIRING_THRESHOLD_SECS } from '../RemoteConfigKeys.js';

/**
 * Fetch backpack items
 * @kind action
 * @param {number} [{page}] - specify the page.
 * @param {number} [{limit}] - specify the limit.
 * @return {Promise} Action promise.
 */
const fetchBackpackItems =
  ({ page = 1, limit = 10 } = {}) =>
  async (dispatch, getState) => {
    const selectPath = ['backpack'];
    const networkingSelectPath = [...selectPath, page];
    const token = getMeData(getState(), 'token');
    if (!token) return dispatch({ type: '' });

    const fetchOptions = {
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${token}`,
      },
    };

    const url = getResourceUrl({ endpoint: '/me/backpack' });
    url.searchParams.append('page', page);
    url.searchParams.append('limit', limit);

    dispatch({
      type: SET_NETWORKING_FETCHING,
      payload: { selectPath: networkingSelectPath },
    });
    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      const payload = await response.json();

      const backpackItems = objectifyArrayById({ array: payload });
      const itemIds = Object.keys(backpackItems);
      dispatch({ type: ADD_BACKPACK_ITEMS, payload: { backpackItems } });

      const totalCount = parseInt(response.headers.get('x-total-count'), 10);
      const links = parseLinkHeader(response.headers.get('link'));
      const nextPage = links && links.next && parseInt(links.next.page, 10);
      const lastPage = links && links.last && parseInt(links.last.page, 10);
      const isFirstPage = page === 1;

      dispatch({
        type: isFirstPage ? SET_LIST_ITEMS : ADD_LIST_ITEMS,
        payload: { selectPath, itemIds, totalCount, nextPage, lastPage },
      });

      if (isFirstPage) {
        const hasExpireSoonItem = payload.some(({ exp }) => {
          const remainingSecs = differenceInSeconds(exp * 1000, Date.now());
          const backpackExpiringThresholdSecs = getRemoteConfigData(
            getState(),
            BACKPACK_EXPIRING_THRESHOLD_SECS
          );
          return (
            remainingSecs > 0 && remainingSecs < backpackExpiringThresholdSecs
          );
        });
        dispatch({
          type: MERGE_OPERATION_DATA,
          payload: { selectPath: ['backpack'], data: { hasExpireSoonItem } },
        });
      }

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath: networkingSelectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath: networkingSelectPath, error },
      });
    }
  };

export default fetchBackpackItems;
