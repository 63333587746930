// LoginSignUpButton.jsx
import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import { color } from '../style/variables.js';
import { ButtonId } from '../resource/mixpanel.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

export const LoginSignUpButton = ({ login }) => {
  const { t } = useTranslation();

  return (
    <>
      <LoginButton
        data-element_id={ButtonId.LoginRegister.ButtonLogin}
        onClick={() => {
          return login({ entry: 'login' });
        }}
      >
        <LoginButtonText>
          {t('login', {
            ns: TranslationNamespace.AUTH,
          })}
        </LoginButtonText>
      </LoginButton>
      <SignupButton
        data-element_id={ButtonId.LoginRegister.ButtonSignup}
        onClick={() => {
          return login({ entry: 'register' });
        }}
      >
        <LoginButtonText>
          {t('sign_up', {
            ns: TranslationNamespace.AUTH,
          })}
        </LoginButtonText>
      </SignupButton>
    </>
  );
};

LoginSignUpButton.propTypes = {
  login: PropTypes.func,
};

LoginSignUpButton.defaultProps = {
  login: () => null,
};

// const StyledLoginSignUpButton = styled.div``;

const AuthButton = styled.button`
  ${ResetButtonStyle};
  margin-left: 8px;
  flex: none;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 12px;
  font-size: 16px;
  border-radius: 4px;
  transition: opacity 0.4s;
  opacity: 1;
  @media (hover: hover) {
    :hover {
      opacity: 0.8;
    }
  }
`;

const LoginButton = styled(AuthButton)`
  color: ${color.white};
  border: solid 1px #474747;
`;

const SignupButton = styled(AuthButton)`
  color: ${color.black};
  background-color: ${color.tealBlue};
`;

const LoginButtonText = styled.div``;

export default LoginSignUpButton;
