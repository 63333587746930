// useMessageDetailType.jsx
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import getMessageData from '../selector/getMessageData.js';
import getAssetData from '../selector/getAssetData.js';
import getMessageDetailLayout from '../selector/getMessageDetailLayout.js';

import fetchAssetManifest from '../action/fetchAssetManifest.js';
import fetchMessageDetail from '../action/fetchMessageDetail.js';

export function useMessageDetailType() {
  const { messageId } = useParams();
  const dispatch = useDispatch();
  const assetIds =
    useSelector(state => getMessageData(state, messageId, 'assetIds')) || [];
  const hasAssetIds = assetIds.length;
  const firstAssetId = assetIds[0];
  const firstAssetContentType =
    useSelector(state => getAssetData(state, [firstAssetId], 'contentType')) ||
    '';
  const duration = useSelector(state =>
    getAssetData(state, [firstAssetId, 'duration'])
  );

  const isVideo = firstAssetContentType.startsWith('video/');
  useEffect(() => {
    if (isVideo && !duration) {
      const fetchData = () => {
        if (!hasAssetIds) {
          dispatch(fetchMessageDetail({ messageId }));
        }
        if (hasAssetIds) {
          dispatch(fetchAssetManifest({ messageId, assetId: firstAssetId }));
        }
      };
      fetchData();
    }
  }, [messageId, hasAssetIds, firstAssetId, duration, isVideo, dispatch]);

  return useSelector(state => getMessageDetailLayout(state, messageId));
}

export default useMessageDetailType;
