// getProductData.js
'use strict';
import { Collection } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

const defaultSelectPath = [];
const defaultDataKey = '';

/**
 * Select product data
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} selectPath - select path.
 * @param {string} dataKey - data key.
 * @return {any} The selected product data.
 */
const getProductData = createCachedSelector(
  (state, selectPath = defaultSelectPath) =>
    state.getIn(['products', ...selectPath]),
  (state, selectPath, dataKey) => dataKey,
  (product, dataKey = defaultDataKey) => {
    let result = product;
    if (dataKey) {
      if ('currencies' === dataKey) {
        const keys = product?.get('currencySkuId')?.keys() || [];
        result = [...keys];
      } else {
        result = product?.get(dataKey);
      }
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)(
  (state, selectPath = defaultSelectPath, dataKey = defaultDataKey) =>
    `${selectPath.join(':')}:${dataKey}`
);

export default getProductData;
