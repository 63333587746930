// BackpackButton.js
'use strict';
import { connect } from 'react-redux';

import BackpackButton from '../component/BackpackButton.jsx';

import addModal from '../action/addModal.js';
import fetchBackpackItems from '../action/fetchBackpackItems.js';
import fetchDailyRewardStatus from '../action/fetchDailyRewardStatus.js';

import getOperationData from '../selector/getOperationData.js';
import getModalData from '../selector/getModalData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getMeData from '../selector/getMeData.js';

const mapStateToProps = state => {
  const isCheckedInDailyReward = getMeData(state, 'isCheckedInDailyReward');
  const isAuthed = !!getMeData(state, 'id');
  return {
    backpackFetchedTimestamp: getNetworkingData(
      state,
      ['backpack', 1],
      'fetchedTimestamp'
    ),
    dailyRewardStatusFetchedTimestamp: getNetworkingData(
      state,
      ['me', 'dailyReward', 'status'],
      'fetchedTimestamp'
    ),
    hasExpireSoonItem: getOperationData(
      state,
      ['backpack'],
      'hasExpireSoonItem'
    ),
    isBackpackModalOpen: ['display', 'opening'].includes(
      getModalData(state, 'BackpackModal', 'status')
    ),
    isFetchingBackpackItems: getNetworkingData(
      state,
      ['backpack', 1],
      'isFetching'
    ),
    isFetchingBackpackDailyRewardStatus: getNetworkingData(
      state,
      ['me', 'dailyReward', 'status'],
      'isFetching'
    ),
    canReceiveDailyReward: !isAuthed || !isCheckedInDailyReward,
    isAuthed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchBackpackItems: () => dispatch(fetchBackpackItems({ page: 1 })),
    fetchDailyRewardStatus: () => dispatch(fetchDailyRewardStatus()),
    openBackpackModal: () => dispatch(addModal({ id: 'BackpackModal' })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackpackButton);
