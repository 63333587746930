// DecryptionWrapper.js
import { connect } from 'react-redux';

import fetchDecryptedResource from '../action/fetchDecryptedResource.js';

import DecryptionWrapper from '../component/DecryptionWrapper.jsx';

import getNetworkingData from '../selector/getNetworkingData.js';
import getDecryptedResourcesData from '../selector/getDecryptedResourcesData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import { FEATURE_RESOURCE_DECRYPTION } from '../RemoteConfigKeys.js';

import {
  REMOVE_DECRYPTED_RESOURCE_DATA,
  CLEAR_NETWORKING_NODES,
} from '../ActionTypes.js';
import env from '../resource/env.js';

const mapStateToProps = (state, { resourceUrl, splashUrl }) => {
  const encryptedResourceUrl =
    resourceUrl?.replace(
      env.PUBLIC_URL_PREFIX,
      env.PUBLIC_ENCRYPTED_URL_PREFIX
    ) || '';
  const encryptedSplashUrl =
    splashUrl?.replace(
      env.PUBLIC_URL_PREFIX,
      env.PUBLIC_ENCRYPTED_URL_PREFIX
    ) || '';

  return {
    isDecryptionRequired:
      getRemoteConfigData(state, FEATURE_RESOURCE_DECRYPTION) === 1,
    encryptedResourceUrl,
    objectUrl: getDecryptedResourcesData(
      state,
      [encryptedResourceUrl],
      'objectUrl'
    ),
    isObjectUrlFetching: getNetworkingData(
      state,
      ['decryptedResources', encryptedResourceUrl],
      'isFetching'
    ),
    isObjectUrlFetched: getNetworkingData(
      state,
      ['decryptedResources', encryptedResourceUrl],
      'isFetched'
    ),
    isObjectUrlError: Boolean(
      getNetworkingData(
        state,
        ['decryptedResources', encryptedResourceUrl],
        'error'
      )
    ),
    encryptedSplashUrl,
    splashObjectUrl: getDecryptedResourcesData(
      state,
      [encryptedSplashUrl],
      'objectUrl'
    ),
    isSplashObjectUrlFetching: getNetworkingData(
      state,
      ['decryptedResources', encryptedSplashUrl],
      'isFetching'
    ),
    isSplashObjectUrlFetched: getNetworkingData(
      state,
      ['decryptedResources', encryptedSplashUrl],
      'isFetched'
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDecryptedResource: data => dispatch(fetchDecryptedResource(data)),
    removeDecryptedResourceData: ({ encryptedResourceUrl }) => {
      return dispatch({
        type: REMOVE_DECRYPTED_RESOURCE_DATA,
        payload: {
          selectPath: [encryptedResourceUrl],
        },
      });
    },
    clearNetworkingNodes: ({ encryptedResourceUrl }) => {
      return dispatch({
        type: CLEAR_NETWORKING_NODES,
        payload: {
          selectPaths: [['decryptedResources', encryptedResourceUrl]],
        },
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DecryptionWrapper);
