// login.js
'use strict';
import addModal from '../action/addModal.js';
import pushToastr from '../action/pushToastr.js';
import { login as loginModal } from '../resource/modalId.js';
import { getIsChinaPartner, getPartnerName } from '../resource/partner.js';
import i18n from '../resource/i18n.js';

/**
 * Login
 * @kind action
 * @return {Promise} Action promise.
 */
const login = () => async dispatch => {
  const isChinaPartner = getIsChinaPartner();
  if (isChinaPartner) {
    return dispatch(
      pushToastr({
        textKey: 're_login_on_partner',
        textKeyOption: {
          partnerName: i18n.t(getPartnerName()),
        },
        color: 'error',
      })
    );
  }
  return dispatch(
    addModal({
      id: loginModal.METHOD_SELECT,
      isHigherThanAll: true,
      transitionStatus: 'comingFromBottom',
    })
  );
};

export default login;
