// fetchAssetManifest.js
'use strict';
import getNetworkingData from '../selector/getNetworkingData.js';

import fetchMediaMenifest from '../resource/fetchMediaMenifest.js';
import { MediaAssetFormat } from '../resource/getMediaAsset.js';

import {
  MERGE_ASSET_DATA,
  ADD_MESSAGES,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Fetch asset manifest
 * @kind action
 * @param {string} {assetId} - asset id.
 * @param {string} {messageId} - message id.
 * @param {MediaAssetFormat} {format} - asset format.
 * @param {object} {[httpProxyHeaders = {}]} - http proxy headers for SSR.
 * @return {Promise} Action promise.
 */
const fetchAssetManifest =
  ({
    assetId,
    messageId,
    format = MediaAssetFormat.MPD,
    httpProxyHeaders = {},
  } = {}) =>
  async (dispatch, getState) => {
    const networkingSelectPath = assetId
      ? ['fetch', 'manifest', messageId, assetId, format]
      : ['fetch', 'manifest', messageId, format];
    const isFetched = getNetworkingData(
      getState(),
      networkingSelectPath,
      'isFetched'
    );
    const isFetching = getNetworkingData(
      getState(),
      networkingSelectPath,
      'isFetching'
    );
    if (isFetching || isFetched) {
      return dispatch({ type: '' });
    }
    dispatch({
      type: SET_NETWORKING_FETCHING,
      payload: { selectPath: networkingSelectPath },
    });
    try {
      const { mpd } = await fetchMediaMenifest({
        id: messageId,
        assetId,
        type: format,
        httpProxyHeaders,
      });
      const duration = mpd.duration;
      const width = mpd.playlists[0].attributes.RESOLUTION.width;
      const height = mpd.playlists[0].attributes.RESOLUTION.height;
      if (assetId) {
        dispatch({
          type: MERGE_ASSET_DATA,
          payload: {
            selectPath: [assetId],
            data: {
              duration,
              width,
              height,
            },
          },
        });
      } else {
        dispatch({
          type: ADD_MESSAGES,
          payload: {
            messages: {
              [messageId]: {
                id: messageId,
                media: {
                  duration,
                  width,
                  height,
                },
              },
            },
          },
        });
      }
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath: networkingSelectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath: networkingSelectPath, error },
      });
    }
  };

export default fetchAssetManifest;
