// getLeaderboardBadgeData.js
'use strict';
import { resourceLog } from '../resource/debug.js';

/**
 * @typedef {object} Result
 * @property {boolean} isLeaderboardBadge - return true when badge is leaderboard badge.
 * @property {string|object} category - return category.
 * @property {string|object} range - return range.
 * @property {number} place - return place.
 * @property {string|object} region - return region.
 * @property {string|object} gender - return gender.
 */

/**
 * get leaderboard badge data.
 * @param {object} payload - payload.
 * @param {string} payload.badgeName - badge name.
 * @return {Result}
 */
export const getLeaderboardBadgeData = ({ badgeName } = {}) => {
  const result = {
    isLeaderboardBadge: false,
    category: null,
    range: null,
    place: 0,
    region: null,
    gender: null,
  };
  try {
    if (badgeName == null || badgeName === '') {
      return result;
    }
    const execResult =
      /(?<type>\w+):(?<gender>\w+):((?<region>\w+)-(?<category>\w+)-(?<range>\w+)):(?<place>\w+)/i.exec(
        badgeName
      );
    const { type, region, category, range, place } = execResult?.groups || {};
    const isLeaderboardBadge = type === 'leaderboard';
    if (isLeaderboardBadge) {
      return {
        ...result,
        isLeaderboardBadge,
        region,
        category,
        range,
        place: Number(place),
      };
    }
    return result;
  } catch (error) {
    resourceLog('error occur at resource/getLeaderboardBadgeData.js');
  }
};

export default getLeaderboardBadgeData;
