// pushToastr.js
'use strict';
import { PUSH_OPERATION_DATA, SET_OPERATION_DATA } from '../ActionTypes.js';
import getOperationData from '../selector/getOperationData.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import { toastrColor as colorKeys } from '../style/variables.js';

const toastrTemplate = {
  textKey: '',
  textKeyOption: null,
  durationSec: 3,
  color: 'rgba(0, 210, 190, 0.6)',
  count: 1,
};

/**
 * Push toastr
 * @kind action
 * @param {string} {textKey} - text key for i18n.
 * @param {object|array} {textKeyOption} - option for i18n.
 * @param {number} {durationSec} - seconds for toastr duration.
 * @param {string} {color} - 'success', 'error' or color codes.
 * @return {Promise} Action promise.
 */
const pushToastr =
  ({
    textKey,
    textKeyOption = {},
    attachedMessage = '',
    durationSec = 5,
    color = 'success',
  }) =>
  async (dispatch, getState) => {
    const stacks = getOperationData(getState(), ['toastr'], 'stacks') || [];
    const existIndex = stacks.findIndex(tItem =>
      tItem.textKey.startsWith(textKey)
    );

    if (existIndex === -1) {
      // new toastr
      const toastr = {
        ...toastrTemplate,
        color: colorKeys[color] || color,
        textKey,
        textKeyOption: Array.isArray(textKeyOption)
          ? {
              ...textKeyOption,
              ns: TranslationNamespace.NOTIFICATION,
            }
          : {
              ns: TranslationNamespace.NOTIFICATION,
              ...textKeyOption,
            },
        durationSec,
        attachedMessage,
      };
      dispatch({
        type: PUSH_OPERATION_DATA,
        payload: {
          selectPath: ['toastr', 'stacks'],
          data: toastr,
        },
      });
    } else {
      // Use `replace` twice not RegExp replace, since parentheses and slash in textKey
      // will get wrong result in regular expression
      const existTime = stacks[existIndex].count || 1;
      stacks[existIndex].count = existTime + 1;
      const firstStartTimestamp = getOperationData(
        getState(),
        ['toastr'],
        'firstStartTimestamp'
      );
      dispatch({
        type: SET_OPERATION_DATA,
        payload: {
          selectPath: ['toastr'],
          data: {
            firstStartTimestamp: existIndex === 0 ? null : firstStartTimestamp,
            stacks,
          },
        },
      });
    }

    const processToastrs = (await import('../action/processToastrs.js'))
      .default;
    return dispatch(processToastrs());
  };

export default pushToastr;
