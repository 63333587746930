// fetchDecryptedResource.js
'use strict';
import fetch from '../resource/customFetch.js';
import handleFetchError from '../resource/handleFetchError.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import responseDecryptionUtil from '../resource/responseDecryptionUtil.js';

import {
  MERGE_DECRYPTED_RESOURCE_DATA,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Fetch encrypted resource, decrypt it, and then create a new Blob.
 * @kind action
 * @param {string} {encryptedResourceUrl} - the encrypted resource url.
 * @return {Promise} Action promise.
 */
const fetchDecryptedResource =
  ({ encryptedResourceUrl }) =>
  async (dispatch, getState) => {
    const selectPath = ['decryptedResources', encryptedResourceUrl];
    const fetchOptions = {
      method: 'GET',
      headers: getHeaders(),
    };

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    try {
      let response = await fetch(encryptedResourceUrl, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchUrl: encryptedResourceUrl,
        });
      }

      const { buffer, contentType } = await responseDecryptionUtil({
        response,
      });

      const blob = new Blob([buffer], { type: contentType });
      const objectUrl = URL.createObjectURL(blob);

      dispatch({
        type: MERGE_DECRYPTED_RESOURCE_DATA,
        payload: {
          selectPath: [encryptedResourceUrl],
          data: {
            resourceUrl: encryptedResourceUrl,
            type: contentType,
            objectUrl,
          },
        },
      });
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchDecryptedResource;
