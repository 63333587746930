// getPublicUrlPath.js

import {
  publicUrlPathConstantsArray,
  defaultPublicUrlPathMap,
} from './publicUrlPathConstants.js';

const isServer = typeof window === 'undefined';

export default function getPublicUrlPath({ publicUrlPathKey }) {
  if (!publicUrlPathConstantsArray.includes(publicUrlPathKey)) {
    return '';
  }

  if (isServer) {
    return (
      globalThis.__PUBLIC_URL_PATH__?.[publicUrlPathKey] ||
      defaultPublicUrlPathMap[publicUrlPathKey] ||
      ''
    );
  }

  return (
    window.__PUBLIC_URL_PATH__?.[publicUrlPathKey] ||
    defaultPublicUrlPathMap[publicUrlPathKey]
  );
}
