// getUserDataByUsername.js
'use strict';
import { Collection } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

/**
 * Select user data by username by username
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} username - username.
 * @param {string} dataKey - the key of the user data.
 * @return {any} The selected user data by username.
 */
const getUserDataByUsername = createCachedSelector(
  (state, username) =>
    state.getIn(['users'])?.find(user => user.get('username') === username),
  (state, username, dataKey) => dataKey,
  (user, dataKey) => {
    let result = undefined;
    if (!user) {
      return result;
    }
    result = user.get(dataKey);
    return result instanceof Collection ? result.toJS() : result;
  }
)((state, username, dataKey) => `${username}:${dataKey}`);

export default getUserDataByUsername;
