// getCanShare.js
'use strict';
import { getIosVersion, getIsOnIOS } from '../resource/getUserAgent.js';

const { major, minor } = getIosVersion();
const iosVersion = +[major, minor].join('.');
const isOnIos = getIsOnIOS();

let canShare = undefined;
/**
 * Get should use `navigator.share`.
 * @return {boolean} Return should use `navigator.share`.
 */
export const getCanShare = () => {
  if (canShare == null) {
    // 12.2 ≤ iOS ≤ 13.7 did not support share click that would trigger a fetch call
    // https://bugs.webkit.org/show_bug.cgi?id=197779
    const isSupportCallFetchIosVersions = iosVersion > 13.7;
    const hasNavigatorShareAPI =
      typeof navigator !== 'undefined' && navigator.share;
    canShare = isOnIos
      ? hasNavigatorShareAPI && isSupportCallFetchIosVersions
      : hasNavigatorShareAPI;
  }
  return canShare;
};
export default getCanShare;
