// getIsAutoChatNotification.js
'use strict';
import { notificationTag } from '../resource/notificationConstants.js';

/**
 * Get is auto chat notification
 * @param {string} tag - notification tag.
 * @param {string} link - notification link.
 * @returns {boolean} Return true if is auto chat notification.
 */
const getIsAutoChatNotification = ({ tag, link }) => {
  const url = new URL(link, 'http://localhost');
  const campaignId = url.searchParams.get('campaign-id');
  return campaignId === 'auto-message-v2' && tag === notificationTag.AUTO_CHAT;
};

export default getIsAutoChatNotification;
