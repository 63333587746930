// getCurrentUserLevel.js
'use strict';

/**
 * Get current user level
 * @param {object} levelData - level name and exp data.
 * @param {number} unixTimestamp - timestamp to compaire with.
 * @returns {string|number} {level} current level key in level data.
 */
const getCurrentUserLevel = ({ levelData, unixTimestamp }) => {
  if (!levelData || !unixTimestamp) return null;

  const maxCurrentLevel = Object.keys(levelData).reduce((maxLevel, level) => {
    // not expired
    if (levelData[level]?.exp - unixTimestamp > 0) {
      return Math.max(maxLevel, level);
    }
    return maxLevel;
  }, 0);

  return maxCurrentLevel;
};

export default getCurrentUserLevel;
